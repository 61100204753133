<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload"></data-iterator>
    <v-dialog v-model="drawer" absolute bottom temporary right width="700px">
      <v-card elevation="0" height="100%">
        <v-card-title class="primary white--text px-4">
          <span class="title"> {{ modelObj._id ? 'Update Role' : 'Add Role'}} </span>
        </v-card-title>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in ['Detail', 'Permissions']" :key="item"> {{ item }} </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-form ref="form" >
          <v-card-text v-if="tab === 0">
            <v-row class="mx-4">
              <v-col cols="12" sm="12">
                <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="12">
                <p>User Role Type</p>
                 <v-radio-group v-model="modelObj.code" row>
                  <v-radio label="Admin" :value="1"></v-radio>
                  <v-radio label="Staff" :value="2"></v-radio>
                  <v-radio label="Student" :value="3"></v-radio>
                  <v-radio label="Parent" :value="4"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text  v-if="tab === 1">
            <v-simple-table class="dense_table bordered--table">
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Read</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="i in listOfModules" :key="i._id">
                  <td>{{i.name}}</td>
                  <td> <v-checkbox v-model="modelObj.permissions[i.name].read"></v-checkbox></td>
                  <td> <v-checkbox v-model="modelObj.permissions[i.name].manage"></v-checkbox></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn tile color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn tile @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      tab: 0,
      modelObj: {
        permissions: {}
      },
      drawer: false,
      payload: {
        defaultView: 'profile',
        create: () => this.openDialogHandler(),
        items: [],
        showSelect: false,
        addClickHandler: (item) => this.viewHandler(item._id),
        actionsList: [{
          text: 'Edit',
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }]
      }
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  mounted () {
    this.getUserRoles()
  },
  computed: {
    ...mapGetters(['listOfModules']),
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Profile',
        value: 'profile'
      }, {
        text: 'Email',
        value: 'email'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getUserRoles () {
      const query = [
        {
          '$lookup': {
            'from': 'Users', 
            'localField': '_id', 
            'foreignField': 'role', 
            'as': 'usersCount'
          }
        }, {
          '$set': {
            'usersCount': {
              '$size': '$usersCount'
            }
          }
        }
      ]
      this.$_execute('post', 'user_roles/query', query).then(response => {
        response.data.forEach(item => {
          item.color = 'white'
          item.caption = `${item.usersCount} Users`
          item.avatar = `${this.imageRootUrl}avatars/${item.avatar}`
        });
        this.payload.items = response.data
      })
    },
    viewHandler(id) {
      this.$router.push(`/users_roles/${id}/users`)
    },
    initModelPermissionObj () {
      if (!this.modelObj.permissions) this.modelObj.permissions = {}
      this.listOfModules.forEach(x => {
        if (!this.modelObj.permissions[x.name]) this.modelObj.permissions[x.name] = {}
        this.modelObj.permissions[x.name].read = Boolean(this.modelObj.permissions[x.name].read)
        this.modelObj.permissions[x.name].manage = Boolean(this.modelObj.permissions[x.name].manage)
      })
    },
    saveUpdateHandler () {
      const method = this.modelObj._id ? 'put' : 'post'
      const url = this.modelObj._id ? `user_roles/${this.modelObj._id}` : 'user_roles'
      this.$_execute(method, url, this.modelObj).then(() => {
        this.getUserRoles()
        this.drawer = false
        this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success' })
      })
    },
    openDialogHandler () {
      this.modelObj = {
        permissions: {}
      }
      this.initModelPermissionObj()
      this.drawer = true
    },
    editHandler (id) {
      this.$_execute('get', `user_roles/${id}`).then(({ data }) => {
        this.modelObj = data
        this.initModelPermissionObj()
        setTimeout(() => { this.drawer = true }, 100)
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'users',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getUserRoles()
        }
      })
    }
  }
}
</script>
